import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import {Apollo} from "apollo-angular";
import {BehaviorSubject, Observable} from "rxjs";
import jwt_decode from "jwt-decode";
import {
  LoginGQL,
  MeGQL,
  UserLoginInput,
  RequestPasswordResetInput,
  SetEmployeePasswordInput,
  NotificationsGQL,
  MarkNotificationsAsReadGQL,
  MarkNotificationsAsReadInput, AdminLoginGQL
} from '../../generated/graphql';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _isAuthenticated = new BehaviorSubject(false);
  constructor(
    private apollo: Apollo,
    private meGQL: MeGQL,
    private loginGQL: LoginGQL,
    private notificationsGQL: NotificationsGQL,
    private markNotificationGQL : MarkNotificationsAsReadGQL,
    private adminLoginGQL : AdminLoginGQL,
    private http: HttpClient
  ) { }

  me() {
    return this.meGQL.watch().valueChanges;
  }

  get isAuthenticated(): Observable<boolean> {
    return this._isAuthenticated.asObservable();
  }

  adminLogin(email: string) {
    return this.adminLoginGQL.mutate({
      input: {
        email
      }
    })
  }

  autoLogin(): boolean {
    if(localStorage.getItem('token')) {
      // @ts-ignore
      const decode: any = jwt_decode(localStorage.getItem('token'));
      const exp = decode.exp;
      const date = new Date(0);
      date.setUTCSeconds(exp);
      if(Date.now() > date.getTime()) {
        return false;
      } else {
        this._isAuthenticated.next(true);
        return true;
      }
    } else {
      return false;
    }
  }

  login(input: UserLoginInput) {
    return this.http.post(environment.restUrl + '/api/auth/login?email=' + input.email + '&password=' + input.password, {})
      .pipe(tap((data: any) => {
        if (data?.token) {
          this._isAuthenticated.next(true);
          localStorage.setItem('token', data?.token);
        }
        return data
      }))
  }

  logout() {
    localStorage.removeItem('token');
    this.apollo.client.resetStore();
  }

  getNotifications() {
    return this.notificationsGQL.watch(undefined,{
      pollInterval: 3000,
    }).valueChanges;
  }

  markNotification(input: MarkNotificationsAsReadInput){
    return this.markNotificationGQL.mutate({input} , {
      refetchQueries: [
        {
          query: this.notificationsGQL.document
        }
      ]
    })
  }
}
