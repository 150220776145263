export const environment = {
  production: true,
  apiUrl: 'https://covento-s2.azurewebsites.net/graphql',
  restUrl: 'https://covento-s2.azurewebsites.net',
  myCoventoUrl: 'http://localhost:5000/graphql',
  hrUrl:  'https://dev.planning.covento.de',
  marketPlaceUrl:  'http://localhost:8000',
  websiteUrl: 'http://localhost:3000'
};

